import { Container, makeStyles, ThemeProvider } from '@material-ui/core';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Clientes from './pages/Clientes';
import Contacto from './pages/Contacto';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros';
import Servicios from './pages/Servicios';
import Footer from './ui/Footer';
import Header from './ui/Header';
import { theme } from './ui/Theme';

const useStyle = makeStyles(theme => ({
  root: {
    maring: 0,
    padding: 0,
    width: '100vw',
    height: '100%',
    minWidth: '100%',
    minHeight: '90%',
    overflowX: 'hidden',
  },
}));

function App() {
  const classes = useStyle();
  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
        <BrowserRouter>
          <Header />

          <Switch>
            <Route exact path="/">
              <Redirect to="/inicio" />
            </Route>
            <Route exact path="/inicio" component={Home} />

            <Route exact path="/nosotros" component={Nosotros} />
            <Route exact path="/servicios" component={Servicios} />
            <Route exact path="/clientes" component={Clientes} />
            <Route exact path="/contacto" component={Contacto} />
            <Redirect to="/inicio" />
          </Switch>
          <Footer />
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
