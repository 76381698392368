import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MyGallery from '../ui/MyGallery';
import { theme } from '../ui/Theme';
import usePageStyle from './usePageStyle';
import { Container } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MainContainerMobile } from '../ui/MainContainer';
import DescriptionCard from '../components/DescriptionCard';

//images
import car from '../assets/hundaynotext.jpeg';
import minivan from '../assets/kangoo2.jpg';
import camioneta from '../assets/sprinternotext.jpg';

const Home = () => {
  const classes = usePageStyle();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <main className={(classes.page, classes.container)}>
      {matches && (
        <MainContainerMobile>
          <Grid container spacing={3}>
            <Grid item>
              <DescriptionCard
                title="Viajes Exclusivos"
                image={car}
                description="El mejor servicio las 24 hs, los 365 días del año. Viajes de corta, media y larga distancia nacional."
              />
            </Grid>
            <Grid item>
              <DescriptionCard
                title="Servicio de paqueteria"
                image={minivan}
                description="Contamos con servicio de paqueteria puerta a puerta."
                imageRight
              />
            </Grid>
            <Grid item>
              <DescriptionCard
                title="Traslado en combis"
                image={camioneta}
                description="Servicio de hasta 19 pasajeros. "
              />
            </Grid>
          </Grid>
        </MainContainerMobile>
      )}
      {!matches && <MyGallery />}
    </main>
  );
};

export default Home;
