import React, { useState, useEffect } from 'react';
import MainContainer from '../ui/MainContainer';
import usePageStyle from './usePageStyle';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import { theme } from '../ui/Theme';
import contactFormSchema from '../validation/contactFormValidation';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { BlueTypography } from '../components/BlueTypography';
import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

// images
import backgroundImage from '../assets/contact1.jpeg';
import phone from '../assets/phone.svg';
import emailImg from '../assets/email.svg';

const useContactStyle = makeStyles(theme => ({
  mainContainer: {
    minHeight: '90%',
    marginTop: '5em',
    padding: 0,
  },
  formContainer: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    marginTop: '2em',
    opacity: '0.5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  field: {
    '&.MuiInputBase-input.MuiInput-input': {
      color: 'blue',
    },
  },
  background: {
    '&.MuiGrid-root.MuiGrid-container': {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '40em',
    },
  },
  button: {
    color: 'white',
    background: '#0b72b9',
    marginLeft: '1rem',

    '&:disabled': {
      color: 'black',
      background: '#3E4756',
      cursor: 'no-drop',
    },
    '&:hover': {
      background: '#0b72b9',
      transform: 'scale(1.02)',
    },
  },
}));

const Contacto = () => {
  const classes = usePageStyle();
  const contact = useContactStyle();
  const matchesSm = useMediaQuery(theme.breakpoints.down('small'));

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    contactFormSchema
      .isValid({
        name: name,
        email: email,
        message: message,
      })
      .then(valid => {
        if (valid) setIsValid(true);
        else setIsValid(false);
      });
  }, [name, email, message]);

  return (
    <main className={(classes.page, classes.container)}>
      <div className={contact.mainContainer}>
        <Grid container direction="row">
          <Grid
            id="h"
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            justifyContent="center"
            lg={4}
            xl={3}
            md={5}
            sm={12}
          >
            <div
              id="f"
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '1em 0',
              }}
            >
              <Grid item alignItems="center" justify="center">
                <BlueTypography variant="h4">Contactanos</BlueTypography>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justify="center"
                style={{ marginTop: '2em' }}
              >
                <Grid item>
                  <img
                    src={emailImg}
                    alt="email"
                    style={{ marginRight: '0.5em', verticalAlign: 'bottom' }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: '1rem' }} variant="body1">
                    ventas@ageri.com.ar
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  alignItems="center"
                  justify="center"
                  style={{ marginTop: '1em' }}
                >
                  <Grid item style={{ marginRight: '0.5em' }}>
                    <img src={phone} alt="phone" />
                  </Grid>
                  <Grid item alignItems="center" justify="center">
                    <Typography style={{ fontSize: '1rem' }} variant="body1">
                      15-6947-5793
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container alignItems="center" justify="center">
                  <form
                    className={contact.formContainer}
                    name="contact"
                    method="post"
                    noValidate
                    autoComplete="off"
                  >
                    <input
                      required
                      type="hidden"
                      name="form-name"
                      value="contact"
                    />
                    <FormControl>
                      <InputLabel
                        style={{ color: '#0b72b9' }}
                        htmlFor="component-simple"
                      >
                        Nombre
                      </InputLabel>
                      <Input
                        id="component-simple"
                        name="name"
                        style={{ color: '#000' }}
                        value={name}
                        onChange={event => setName(event.target.value)}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        style={{ color: '#0b72b9' }}
                        htmlFor="component-simple"
                      >
                        Correo
                      </InputLabel>
                      <Input
                        id="component-simple"
                        name="email"
                        style={{ color: 'black' }}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        style={{ color: '#0b72b9' }}
                        htmlFor="component-simple"
                      >
                        Tu consulta
                      </InputLabel>
                      <Input
                        id="component-simple"
                        style={{ color: 'black' }}
                        name="message"
                        multiline
                        value={message}
                        onChange={event => setMessage(event.target.value)}
                      />
                    </FormControl>
                    <Grid item justify="center">
                      <Button
                        type="submit"
                        disabled={!isValid}
                        className={contact.button}
                      >
                        Enviar mensaje
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Hidden smDown>
            <Grid
              className={contact.background}
              container
              direction="column"
              lg={8}
              md={7}
              xl={9}
            ></Grid>
          </Hidden>
        </Grid>
      </div>
    </main>
  );
};

export default Contacto;
