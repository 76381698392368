import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { Grid, Paper } from '@material-ui/core';

//images

import client1 from '../assets/clientes/galileotec.png';
import client2 from '../assets/clientes/guascor.png';
import client3 from '../assets/clientes/intemed.png';
import client4 from '../assets/clientes/lokering.png';
import client5 from '../assets/clientes/siemens.png';
import client6 from '../assets/clientes/worldline.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: 400,
    marginTop: '5em',
    overflow: 'hidden',
    padding: '2em',
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      height: 'fit-content',
    },
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      justify: 'center',
    },
  },

  avatarRect: {
    height: 150,
    width: 300,
    marginBottom: '1em',
  },
  avatarCirc: {
    height: 160,
    width: 160,
    marginBottom: '1em',
  },
  item: {
    width: '100%',
    background: 'white',
  },

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const tileData = [
  {
    img: client1,
    title: 'PEUGEOT 301',
  },
  {
    img: client2,
    title: 'RENAULT FLUENCE ',
  },
  {
    img: client3,
    title: 'VOLKSWAGEN VENTO',
  },
  {
    img: client4,
    title: 'VOLKSWAGEN VIRTUS',
  },
  {
    img: client5,
    title: 'PEUGEOT 408',
  },
  {
    img: client6,
    title: 'VOLKSWAGEN SURAN',
  },
];

export default function GridCircularItems({ itemsTitle }) {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.gridContainer}
      >
        <Grid item container className={classes.item} justify="space-evenly">
          <Grid item>
            <Avatar
              variant="rounded"
              className={classes.avatarRect}
              key={tileData[1].img}
              src={tileData[1].img}
              alt={tileData[1].title}
            ></Avatar>
          </Grid>

          <Grid item>
            <Avatar
              className={classes.avatarCirc}
              key={tileData[0].img}
              src={tileData[0].img}
              alt={tileData[0].title}
            ></Avatar>
          </Grid>

          <Grid item>
            <Avatar
              variant="rounded"
              className={classes.avatarRect}
              style={{ height: 160, width: 210 }}
              key={tileData[2].img}
              src={tileData[2].img}
              alt={tileData[2].title}
            ></Avatar>
          </Grid>
        </Grid>
        <Grid item container className={classes.item} justify="space-evenly">
          <Grid item>
            <Avatar
              variant="rounded"
              className={classes.avatarRect}
              key={tileData[4].img}
              src={tileData[4].img}
              alt={tileData[4].title}
            ></Avatar>
          </Grid>

          <Grid item>
            <Avatar
              className={classes.avatarCirc}
              key={tileData[3].img}
              src={tileData[3].img}
              alt={tileData[3].title}
            ></Avatar>
          </Grid>

          <Grid item>
            <Avatar
              variant="rounded"
              className={classes.avatarRect}
              style={{ height: 150, width: 240 }}
              key={tileData[5].img}
              src={tileData[5].img}
              alt={tileData[5].title}
            ></Avatar>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
