import React from 'react';
import ImageGallery from 'react-image-gallery';
import './MyGAllery.css';
import car from '../assets/kangoo.png';
import car2 from '../assets/hyunslide3.jpg';
import camioneta from '../assets/sprinter.png';
import { Container } from '@material-ui/core';

const text = () => {
  return (
    <h1 style={{ color: 'white', position: 'absolute', top: '100px' }}>
      hello
    </h1>
  );
};

const images = [
  {
    original: car2,
    height: '900px',
  },
  {
    original: car,
  },

  {
    original: camioneta,
  },
];

const MyGallery = () => {
  return (
    <div
      style={{ 'min-height': '60%', 'margin-top': '5em', background: 'black' }}
    >
      <ImageGallery
        items={images}
        showThumbnails={false}
        autoPlay={true}
        onSlide={text}
      />
    </div>
  );
};

export default MyGallery;
