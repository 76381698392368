import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  root: {
    width: 330,
    backgroundColor: 'white',
    color: 'black',
  },
  bullet: {
    display: 'inline-block',
    color: theme.palette.common.blue,
    margin: '0 2px',
    transform: 'scale(1.5)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function CardBulletList({ list }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        {list.map(item => (
          <p
            style={{ fontFamily: 'Roboto, sans-serif', marginBottom: '0.5em' }}
          >
            {bull} {item}
          </p>
        ))}
      </CardContent>
    </Card>
  );
}
