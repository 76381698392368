import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TimeToLeaveRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  media: {
    height: 300,
    width: '65%',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },
  root: {
    display: 'flex',
    width: '80vw',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',

    width: '35%',
  },
  content: {
    flex: '1 0 auto',
  },
  text: {
    color: theme.palette.common.blue,
  },
  cardContent: {
    display: 'flex',
    direction: 'column',
    justify: 'center',
    height: '100%',
  },
}));

const DescriptionCard = ({ title, image, description, imageRight }) => {
  const classes = useStyles();
  const cardImage = (
    <CardMedia
      className={classes.media}
      image={image}
      title={TimeToLeaveRounded}
    />
  );

  return (
    <Card className={classes.root}>
      {!imageRight && cardImage}

      <div className={classes.details}>
        <CardContent style={{ marginTop: '3em' }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.text}
          >
            {title}
          </Typography>
          <Typography
            className={classes.text}
            variant="body2"
            color="textPrimary"
            component="p"
          >
            {description}
          </Typography>
        </CardContent>
      </div>
      {imageRight && cardImage}
    </Card>
  );
};

export default DescriptionCard;
