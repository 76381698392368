import { Card, Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { BannerClientes, BannerNosotros } from '../ui/Banner';

import usePageStyle from './usePageStyle';
import { BlueTypography } from '../components/BlueTypography';
import GridItems from '../components/GridItems';
import CardBulletList from '../components/CardBulletList';
import GridCircularItems from '../components/GridCircularItems';

const porqueElegirnosLista = [
  'Le ofrecemos una cuenta corriente.',
  'Operamos con trajetas Amex y Visa.',
  'Todo el año a su servicio durante las 24 horas.',
];

const Clientes = () => {
  const classes = usePageStyle();

  return (
    <>
      <BannerClientes />
      <main
        className={(classes.page, classes.container)}
        style={{ padding: '2em 4em' }}
      >
        <Grid
          container
          spacing={3}
          direction="column"
          style={{ marginTop: '1em' }}
        >
          <Grid item>
            <BlueTypography variant="h4" align="center">
              Confiarnos su traslado será su mejor elección
            </BlueTypography>
          </Grid>
          <Grid item>
            <Container maxWidth="lg">
              <Typography variant="h6">
                Nuestra experiencia y la excelencia de nuestro servicio nos han
                permitido trabajar co empresas de reconocimiento internacional.
                En Ageri estamos en crecimiento constante para estar siempre a
                la altura de nuestros clientes. No solo por la seriedad con la
                que tomamos nuestro compromiso, sino también por el confort que
                aseguramos en cada viaje, para que llegue a destino de la mejor
                manera.
              </Typography>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <GridCircularItems itemsTitle="Nuestra Flota" />
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default Clientes;
