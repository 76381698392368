// @ts-nocheck
import { createMuiTheme } from '@material-ui/core/styles';

//we use this function to create a theme than then we pass to the themeprovider

const myBlue = '#0b72b9';
const myOrange = '#ffba60';
const black = '#010101';

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    common: {
      // @ts-ignore
      orange: `${myOrange}`,
      blue: `${myBlue}`,
    },
    primary: {
      main: `${black}`,
      dark: '#3333',
    },
    secondary: {
      main: `${myOrange}`,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    textTransform: 'none',
    color: 'white',
    tab: {
      fontFamily: 'Raleway',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '1rem',
    },
  },
  page: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',

    color: 'white',
  },

  overrides: {
    MuiInputLabel: {
      root: {
        color: `${myBlue}`,
        fontSize: '1rem',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `2px solid ${myBlue}`,
        },
        '&:hover:before': {
          borderBottom: `2px solid ${myBlue}`,
        },
      },
    },
  },
});
