import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    width: '50vw',
  },

  img: {
    width: 220,
    height: 220,
  },
}));

export default function ListItemDescription({ title, image, description }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} justify="center">
          <Grid item lg={4}>
            <Avatar className={classes.img} alt="complex" src={image} />
          </Grid>
          <Grid item container lg={8}>
            <Grid
              item
              container
              direction="column"
              spacing={3}
              alignItems="center"
            >
              <Grid item style={{ alignSelf: 'flex-start' }}>
                <Typography gutterBottom variant="subtitle1">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
