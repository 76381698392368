import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { theme } from '../ui/Theme';

//images

import peugeout301 from '../assets/flota/peugot301.png';
import peugeout408 from '../assets/flota/peugot408.png';
import virtus from '../assets/flota/volkswagen-virtus.png';
import suran from '../assets/flota/suran.png';
import vento from '../assets/flota/vento.png';
import fluence from '../assets/flota/renaulfluence.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '80%',
    height: 800,
    [theme.breakpoints.down('xs')]: {
      width: 600,
      height: 700,
    },
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

const tileData = [
  {
    img: peugeout301,
    title: 'PEUGEOT 301',
  },
  {
    img: fluence,
    title: 'RENAULT FLUENCE ',
  },
  {
    img: vento,
    title: 'VOLKSWAGEN VENTO',
  },
  {
    img: virtus,
    title: 'VOLKSWAGEN VIRTUS',
  },
  {
    img: peugeout408,
    title: 'PEUGEOT 408',
  },
  {
    img: suran,
    title: 'VOLKSWAGEN SURAN',
  },
];

export default function GridItems({ itemsTitle }) {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const imgCols = matches ? 2 : 1;

  return (
    <div className={classes.root}>
      <GridList cellHeight={180} className={classes.gridList} spacing={2}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader style={{ marginLeft: '40%' }} component="div">
            {itemsTitle}
          </ListSubheader>
        </GridListTile>
        {tileData.map(tile => (
          <GridListTile key={tile.img} cols={imgCols}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar title={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
