import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.8,
    height: '6vh',
    width: '100%',
    padding: '1em',
    marginBottom: 0,
  },
  gridContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    overFlowX: 'hidden',
  },
}));

const Footer = () => {
  const classes = useStyle();
  return (
    <footer className={classes.root}>
      <Grid
        className={classes.gridContainer}
        container
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid item sm={6}>
          <Typography variant="h6" align="center" color="textPrimary">
            2021 &copy; Ageri
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
