import { makeStyles } from '@material-ui/core/styles';

const usePageStyle = makeStyles(theme => ({
  page: {
    ...theme.page,
    background: '#F5F5F5',
    width: '100%',
    height: '100%',
    minHeight: '60vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
    overflowX: 'hidden',
  },
  container: {
    minHeight: '92vh',
    backgroundColor: '#F5F5F5',
    marginTop: -16,
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      minHeight: '92vh',
    },
  },
  blackContainer: {
    minHeight: '92vh',
    backgroundColor: '#030303',
    marginTop: -16,
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      minHeight: '92vh',
    },
  },
}));

export default usePageStyle;
