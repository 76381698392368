import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { BannerServicios } from '../ui/Banner';

import usePageStyle from './usePageStyle';
import { BlueTypography } from '../components/BlueTypography';
import GridItems from '../components/GridItems';
import ListItemDescription from '../components/ListItemDescription';
import CardBulletList from '../components/CardBulletList';
import transporte from '../assets/flota/suran.png';
import paquete from '../assets/package.png';
import sprinter from '../assets/sprinterinside.jpg';

const nuestraExperienciaLista = [
  'Atención programada las 24 horas de los 365 días del año.',
  'Conductores con licencia nacional profesional',
  'Unidades aseguradas con cobertura de servicion de transporte de pasajeros.',
  'Unidades habilitadas con VTV.',
  'Seguimiento de toda nuestra flota equipada con GPS y con telefonia móvil.',
  'Contacto personalizado de nuestros operadores con el pasajero desde su llamada hasta que finaliza el servicio',
];

const Servicios = () => {
  const classes = usePageStyle();
  return (
    <>
      <BannerServicios />
      <main
        className={(classes.page, classes.container)}
        style={{ padding: '2em 4em' }}
      >
        <Grid container spacing={3} direction="column">
          <Grid item>
            <BlueTypography variant="h4" align="center">
              Nuestros Servicios
            </BlueTypography>
          </Grid>
          <Grid item>
            <Container maxWidth="lg">
              <Typography variant="subtitle1">
                Nuestro servicio se caracteriza por la calidad, seriedad y
                puntualidad. Nos adaptamos a su pedido para cumplir con sus
                expectativas. Disponemos de modernas unidades dotadas de los más
                avanzados equipamientos para garantizarle la mayor seguridad y
                confort en su viaje.
              </Typography>
            </Container>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="column"
            style={{ marginTop: '1em' }}
          >
            <Grid item>
              <BlueTypography variant="h4" align="center">
                Nuestra experiencia a su servicio
              </BlueTypography>
            </Grid>
            <Grid item>
              <Container maxWidth="lg">
                <Typography variant="subtitle1">
                  Brindamos el servicio más completo del mercado. Trabajamos
                  para cubrir todas sus expectativas y hacerlo sentir seguro y
                  confortable. Nos especializamos en la atención a Empresas y a
                  sus Ejecutivos con la discreción y la reserva que el servicio
                  impone.
                </Typography>
              </Container>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: '2em', marginBottom: '2em' }}
              >
                <CardBulletList list={nuestraExperienciaLista} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems="stretch"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <ListItemDescription
                title="Transporte de pasajeros"
                image={transporte}
                description="Transporte para empresas y eventos corporativos. 
                Traslado desde y hacia aeropuertos, terminales y hoteles.
                Excursiones, días de campo, viajes turisticos.
                Cumpleaños, casamientos y eventos especiales
                Viajes de corta, media y larga distancia nacional."
              />
            </Grid>
            <Grid item>
              <ListItemDescription
                title="Entrega de paquetes"
                image={paquete}
                description="Contamos con el mejor servicio de paqueteria puerta a puerta."
              />
            </Grid>
            <Grid item>
              <ListItemDescription
                title="Traslados en minibus"
                image={sprinter}
                description="Minivan con capacidad de hasta 7 pasajeros.
                City tours.
                Recorridos con espera."
              />
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default Servicios;
