import { Card, Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { BannerNosotros } from '../ui/Banner';

import usePageStyle from './usePageStyle';
import { BlueTypography } from '../components/BlueTypography';
import GridItems from '../components/GridItems';
import CardBulletList from '../components/CardBulletList';

const porqueElegirnosLista = [
  'Le ofrecemos una cuenta corriente.',
  'Operamos con trajetas Amex y Visa.',
  'Todo el año a su servicio durante las 24 horas.',
];

const Nosotros = () => {
  const classes = usePageStyle();

  return (
    <>
      <BannerNosotros />
      <main
        className={(classes.page, classes.container)}
        style={{ padding: '2em 1em' }}
      >
        <Grid container spacing={3} direction="column">
          <Grid item>
            <BlueTypography variant="h4" align="center">
              ¿QUIÉNES SOMOS?
            </BlueTypography>
          </Grid>
          <Grid item>
            <Container maxWidth="lg">
              <Typography variant="subtitle1">
                Somos una empresa de traslados ejecutivos con 20 años de
                experiencia en el rubro, una empresa familiar dedicada a brindar
                servicios de movilidad exclusivamente para empresas.
              </Typography>
            </Container>
          </Grid>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <BlueTypography variant="h4" align="center">
                ¿POR QUÉ ELEGIRNOS?
              </BlueTypography>
            </Grid>
            <Grid item>
              <Container maxWidth="lg">
                <Typography variant="subtitle1">
                  Brindamos un servicio ejecutivo de excelencia apuntando a
                  marcar la diferencia en cada viaje realizado. Disponemos de
                  atención las 24 horas los 365 días del año. Realizamos viajes
                  desde y hacia el interior del país. Nuestros clientes pueden
                  abonar los servicios prestados mediante tarjetas de crédito
                  y/o cuenta corriente.
                </Typography>
              </Container>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: '2em', marginBottom: '2em' }}
              >
                <CardBulletList list={porqueElegirnosLista} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GridItems itemsTitle="Nuestra Flota" />
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default Nosotros;
