import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useScrollTrigger,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getThemeProps } from '@material-ui/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/logos/logo.png';

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

//make styles is useful to create custom styles without having to use css
const useStyles = makeStyles(theme => ({
  appBar: {
    height: '4em',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.8',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1em',
    },
  },
  toolbar: {
    overflowY: 'hidden',
  },
  toolbarMargin: {
    //we copy all the properties of material ui toolbar
    ...theme.mixins.toolbar,
    marginBottom: '2em',
    //media query sintaxis from material ui
    [theme.breakpoints.down('md')]: {
      marginBottom: '1em',
    },
    // generally the phones have this size
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1.5em',
    },
  },
  logoContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logo: {
    height: '10em',
    width: '16em',
    marginLeft: '1rem',
    //media query sintaxis from material ui
    [theme.breakpoints.down('md')]: {
      height: '9em',
      width: '15em',
    },
    // generally the phones have this size
    [theme.breakpoints.down('xs')]: {
      height: '8em',
      width: '10em',
      marginLeft: '6rem',
    },
  },
  tabContainer: {
    marginLeft: 'auto',
    marginRight: '3em',
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: '25px',
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: '50px',
    marginRight: '25px',
    marginLeft: '50px',
    height: '45px',
  },
  menu: {
    backgroundColor: theme.palette.common.blue,
    borderRadius: '0px',
  },
  menuItem: {
    ...theme.typography.tab,
    color: 'white',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.8,
    paddingTop: '4em',
  },
  drawerItemText: {
    ...theme.typography.tab,
    color: 'white',
  },
  iconDrawerContainer: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawerIcon: {
    height: '50px',
    width: '50px',
  },
}));

const menuItemPosition = {
  inicio: 0,
  nosotros: 1,
  servicios: 2,
  clientes: 3,

  contacto: 4,
};

const Header = props => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState(menuItemPosition.inicio);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (e, value) => {
    setValue(value);
  };

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleMenuItemClik = (event, index) => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedMenuIndex(index);
  };

  const handleClose = e => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case '/inicio':
        setValue(menuItemPosition.inicio);
        break;
      case '/nosotros':
        setValue(menuItemPosition.nosotros);

        break;
      case '/servicios':
        setValue(menuItemPosition.servicios);
        break;
      case '/clientes':
        setValue(menuItemPosition.clientes);
        break;

      case '/contacto':
        setValue(menuItemPosition.contacto);
        break;
      default:
        setValue(-1);
        break;
    }
  }, [value]);

  const tabs = (
    <>
      <Tabs
        onChange={handleChange}
        value={value}
        className={classes.tabContainer}
        //white indicator color we can chage the color of the line under our tabs
        indicatorColor="primary"
      >
        <Tab
          className={classes.tab}
          // we can pass link as a prop and now this tab has all the functionality of link
          component={Link}
          to="/inicio"
          label="Inicio"
        />
        <Tab
          className={classes.tab}
          // we can pass link as a prop and now this tab has all the functionality of link
          component={Link}
          to="/nosotros"
          label="Nosotros"
        />
        <Tab
          className={classes.tab}
          component={Link}
          to="/servicios"
          label="Servicios"
        />
        <Tab
          className={classes.tab}
          component={Link}
          to="/clientes"
          label="Clientes"
        />

        <Tab
          className={classes.tab}
          component={Link}
          to="/contacto"
          label="Contacto"
        />
      </Tabs>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        //classes gives us access to the styles directly
        classes={{
          paper: classes.menu,
        }}
        // with menu list props we have access to the parent component of menu (read the api)
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
        elevation={0}
      ></Menu>
    </>
  );

  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <List disablePadding>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(menuItemPosition.inicio);
            }}
            divider
            button
            component={Link}
            to="/"
            selected={value === menuItemPosition.inicio}
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Inicio
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(menuItemPosition.nosotros);
            }}
            divider
            button
            component={Link}
            to="/nosotros"
            selected={value === menuItemPosition.nosotros}
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Nosotros
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(menuItemPosition.servicios);
            }}
            divider
            button
            component={Link}
            to="/servicios"
            selected={value === menuItemPosition.servicios}
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Servicios
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(menuItemPosition.clientes);
            }}
            divider
            button
            component={Link}
            to="/clientes"
            selected={value === menuItemPosition.clientes}
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Clientes
            </ListItemText>
          </ListItem>

          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(menuItemPosition.contacto);
            }}
            divider
            button
            component={Link}
            to="/contacto"
            selected={value === menuItemPosition.contacto}
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Contacto
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.iconDrawerContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </>
  );

  return (
    <>
      <ElevationScroll>
        {/* default position of AppBar is fixed */}
        <AppBar className={classes.appBar}>
          {/* the Toolbar put things horizontal, disableGutters is for removing the padding */}
          <Toolbar className={classes.toolbar} disableGutters>
            <Button
              component={Link}
              to="/inicio"
              disableRipple
              className={classes.logoContainer}
              onClick={() => {
                setValue(menuItemPosition.inicio);
              }}
            >
              <img className={classes.logo} src={logo} alt="logo ageri" />
            </Button>
            {/* matches only match if screen size is 600px or down */}
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default Header;
