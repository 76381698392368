import { makeStyles } from '@material-ui/core';
import React from 'react';
import portada from '../assets/car.webp';
import serviciosImg from '../assets/chauferservice.jpg';

//cliente portada
import shakinghands from '../assets/shakinghands.jpg';
import shakinghands2 from '../assets/shakinghands2.jpg';
import shakinghands3 from '../assets/shakinghands3.jpg';

const useStyle = makeStyles(theme => ({
  container: {
    backgroundImage: `url(${portada})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '4em',
    height: '19em',
  },
}));

export const BannerNosotros = () => {
  const classes = useStyle();
  return <div className={classes.container}></div>;
};

const useStyleServicios = makeStyles(theme => ({
  container: {
    backgroundImage: `url(${serviciosImg})`,
    filter: 'grey',
    '-webkit-filter': `grayscale(100%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '4em',
    height: '19em',
  },
}));

export const BannerServicios = () => {
  const classes = useStyleServicios();
  return <div className={classes.container}></div>;
};

const useStyleClientes = makeStyles(theme => ({
  container: {
    backgroundImage: `url(${shakinghands2})`,
    filter: 'grey',
    '-webkit-filter': `grayscale(100%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '4em',
    height: '19em',
  },
}));

export const BannerClientes = () => {
  const classes = useStyleClientes();
  return <div className={classes.container}></div>;
};
