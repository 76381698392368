import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => ({
  container: {
    minHeight: '90%',
    marginTop: '5em',
    padding: '3em 7em',
  },
  containerMobile: {
    minHeight: '90%',
    marginTop: '5em',
    padding: '3em 1.2em',
  },
}));

export const MainContainer = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.container}>{children}</div>;
};

export const MainContainerMobile = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.containerMobile}>{children}</div>;
};
